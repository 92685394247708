<template>
  <div class="container">
    <div class="content">
        <div class="title">{{ title }}</div>
        <p v-for="paragraph in content" :key="paragraph">{{ paragraph }}</p>
        <router-link v-if="buttonText" :to="buttonLink" custom v-slot="{href, navigate}">
            <button class="button" :href="href" @click="navigate">
                {{ buttonText }}<i class="bi-arrow-right"/>
            </button>
        </router-link>    
        <ul>
          <li v-for="link in links" :key="link">
            <router-link :to="link.dest">
              {{ link.link }}
            </router-link>
          </li>
        </ul>
    </div>
    <div  class="images">
        <img v-if="image" :src="one">
        <img v-if="image" :src="two">
    </div>
  </div>
</template>

<script>
import one from '../assets/BROADSTREET-122.jpg'
import two from '../assets/BROADSTREET-147.jpg'
export default {
    data() {
      return {
          one,
          two
      }
    },
    props:{
        title: String,
        content: Array,
        buttonText: String,
        buttonLink: String,
        links: Array,
        image: Boolean
    },
    components: {
    }
}
</script>

<style scoped>
a{
  color: var(--secondary-variant);
}
a:hover{
  color: var(--secondary-variant);
}
a:visited{
  color: var(--secondary-variant);
}
.container{
  padding: 4rem 1.5rem 1rem 1.5rem;
  color: var(--background);
}
.images{
    display: none;
}
button{
	line-height: 1rem;
}
button >  [class^="bi-"]::before{
  padding-left: 1rem;
  font-weight: bold !important;
}

ul{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem 0;
  padding: 0;
  list-style-type: none;
}
li{
  font-size: 0.85rem;
  color: var(--background);
  font-weight: 500;
  width: max-content;
  padding-left: 0.2rem;
}
li::after{
  content: ' •';
}
li:last-child::after{
  content: '';
}
@media screen and (min-width: 375px){
  .container{
    padding: 4rem 2.5rem 1rem 1.5rem;
  }
}
@media screen and (min-width: 768px){
  .container{
    padding: 4rem var(--padding1);
  }
  button{
    width: 45%;
	margin-left:0;
  }
}
@media screen and (min-width: 1024px) {
    .container{
        display: flex;
        padding: 4rem var(--padding1);
    }
    .content{
        width: 40%;
    }
    .images{
        display:flex;
        width: 40%;
        padding-left: 6rem;
        justify-content: center;
    }
    .images > img{
        width: 20rem;
        height: 15rem;
        object-fit: contain;
        z-index: 2;
    }
    .images > img:nth-child(even){
        margin-left: -6rem;
        margin-top: 10rem;
        z-index: 1;
    }
    button{
        width: auto;
    }
  
}
@media screen and (min-width: 1440px) {
        .container{
            padding: 4rem var(--padding2);
        }
    } 
</style>