import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [ 
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',    
    name: 'Home',
    component: Home
  },
  {
    path: '/dentalcare',
    name: 'Dental Care',
    component: () => import ('../views/DentalCare')
  },
  {
    path: '/dentalcare/general',
    name: 'General Dentistry',
    component: () => import ('../views/GeneralDentistry')
  },
  {
    path: '/dentalcare/restorative',
    name: 'Restorative',
    component: () => import ('../views/Restorative')
  },
  {
    path: '/dentalcare/dentures',
    name: 'Dentures',
    component: () => import ('../views/Dentures')
  },
  {
    path: '/dentalcare/dentalimplants',
    name: 'Dental Implants',
    component: () => import ('../views/DentalImplants')
  },
  {
    path: '/dentalcare/orthodontics',
    name: 'Orthodontics',
    component: () => import ('../views/Orthodontics')
  },
  {
    path: '/dentalcare/whitening',
    name: 'Whitening',
    component: () => import ('../views/Whitening')
  },
  {
    path: '/dentalcare/oralsurgery',
    name: 'Oral Surgery',
    component: () => import ('../views/OralSurgery')
  },
  {
    path: '/dentalcare/rootcanaltreatment',
    name: 'Root Canal Treatment',
    component: () => import ('../views/RootCanalTreatment')
  },
  {
    path: '/dentalcare/periodonticsandhygiene',
    name: 'Periodontics & Hygiene',
    component: () => import ('../views/PeriodonticsAndHygiene')
  },
  {
    path: '/info',
    name: 'Info',    
    component: () => import('../views/Info.vue')
  },
  {
    path: '/prices',
    name: 'Prices',
    component: () => import('../views/Prices.vue')
  },
  {
    path: '/staff',
    name: 'Staff',
    component: () => import('../views/Staff.vue')
  },
  {
    path: '/staff/:dentist',
    name: 'StaffDetails',
    component: () => import('../views/StaffDetail.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
 }
})

export default router
