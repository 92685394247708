<template>
    <div class="container" :class="{ light: light }">      
        <div class="text">
            <h2>{{ heading }}</h2>
            <p v-for="paragraph in content" :key="paragraph"> {{ paragraph }}</p>
          <br/>
            <router-link :to="link" v-if="link" custom v-slot="{href, navigate}">
                <button :href="href" @click="navigate" :class="{ dark : light }" v-if="button">
                    {{ button }}<i class="bi-arrow-right"/>
                </button>
            </router-link>
        </div>       
        <div class="img">
            <img v-if="img" :src=image>
        </div>
    </div>
</template>

<script>
import banner from '../assets/banner-2.jpg'

export default {
  props: {
    heading: String,
    content: Array,
    button: String,
    light: Boolean,
    img: Boolean,
    link: String,
    image: String
  },
  components: {
  },
    data() {
      return {
          banner
      }
    }
}
</script>

<style scoped>
    p{
      margin-bottom: 1rem;
    }
    img{
        display: none;
    }
    .light {
        background-color: var(--font-light) !important;
        color: var(--font-dark) !important;
    }
    .container{
        background: var(--primary);
        padding: 1rem 1.5rem;
        color: var(--background);
    }
    button{
        width: auto;
    }
    button >  [class^="bi-"]::before{
        padding-left: 1rem;
        font-weight: bold !important;
    }
    @media screen and (min-width: 768px) {
        .container{
            padding: 4rem 6rem;
        }
        h2{
          font-size: 2rem;
        }
        p, button{
          font-size: 1.2rem;
        }       
    }
    @media screen and (min-width: 1024px) {
        img{
            display: block;
            width: 35rem;
            height: 25rem;
            right: 0;
            
        }
        .img{
            overflow: hidden;
            width: auto;
            margin: auto;
        }
        .text{
            width: 50%;

        }
        .container{
            display: flex;
            padding: 2rem var(--padding1);
        }
        h2, p{
            max-width: 70%;
        }
        h2{
            font-size: 1.2rem;
        }
        p, button{
            font-size: 1rem;           
        }
        button{
            margin: 0;
            padding: .9rem;
        }
    }
    @media screen and (min-width: 1440px) {
        .container{
            padding: 4rem var(--padding2);
        }
    }    
</style>