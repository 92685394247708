<template>
    <div class="card">
      <div class="card-content">
        <img v-if="seal" :src="logo" />
        <div class="title">
            <i v-if="icon" :class="icon"></i><p>{{title}}</p>
        </div>
        <div class="content">
            <div class="table" v-if="hours">
                <table>
                    <tr v-for="hour, day in hours" :key="day">
                        <td>{{day}}</td><td>{{hour}}</td>
                    </tr>
                </table>
            </div>
            <p v-for="item in content" :key="item" v-html="item"></p> 
            
        </div>
        <div class="footer">
            <p v-for="item in footer" :key="item">{{item}}</p>
            <div class="phone" v-if="phone"><i class="bi-telephone"></i> <a :href="'tel:'+phone">{{phone}}</a></div>
            <div class="link" v-if="link"><a :href="linkdest" target="_blank"><strong>{{link}}</strong> </a> <i class="bi-box-arrow-up-right"/></div>
            <router-link v-if="buttonLink" :to="buttonLink" custom v-slot="{href, navigate}">
                <button class="button" :href="href" @click="navigate" v-if="button" >
                    {{ button }}<i class="bi-arrow-right"/>
                </button>
            </router-link>
        </div>
      </div>
    </div>
</template>

<script>
import logo from '../assets/light/BSDP_DP_WHITTLESEY_SEAL_LOGO.svg'

export default {
    data(){
       return{
         logo
       }
   },
   props:{
       icon: String,
       title: String,
       content: Array,
       hours: Object,
       footer: Array,
       phone: String,
       link: String,
       linkdest: String,
       button: String,
       seal: Boolean,
       buttonLink: String
   }
}
</script>

<style scoped>
    a{
        text-decoration: none;
        color: var(--foreground);
    }
    .card {
      display: flex;
      width: 100vw;
      font-weight: normal;
      flex-direction: column;
      background-color: var(--secondary-variant);
      align-self: center;
      border-radius: 0rem;
      color: var(--foreground);
    }
    .card-content {
      padding: 2rem 2rem 2rem 2rem;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
    }
    
    .card:nth-child(odd){
        background-color: var(--primary-variant);
    }
    .title{
        display: flex;
        flex-direction: row;
        color: var(--font-dark);
        max-height: 30%;
        margin-bottom: 1rem;
    }
    .title > i{
        align-self: center;
        text-align: right;
        padding-right: 1rem;
        font-size: 1.5rem;    
    }
    .title > [class^="bi-"]::before{
        font-weight: bold !important;
    }
    .title > p{
        align-self: center;
        font-weight: bold;
        font-size: 1.5rem;
        font-family: 'Poppins';
    }
    .content, .footer{
        width: 100%;
        font-size: .95rem;    
        white-space: pre-line;
    }
    .footer{
        margin-top: 1rem;
        align-self: flex-end;
    }
    .footer > p {
        margin: 0;
    }
    .phone{
        text-align: left;
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--font-dark);
        text-decoration: underline var(--font-dark);
    }
    .phone > i{
        padding-right: .5rem;
    }
    .link, .phone{
        text-decoration: underline;    
    }
    .link >  [class^="bi-"]::before{
        font-weight: bold !important;       
    }
    
    table{
        border-spacing: 0;
        background-color: var(--secondary-variant);
        font-weight: 100;        
        min-width: 100%;
        padding: 0;
    }

    th{
        text-align: left;
    }

    tr:nth-child(odd){
      background-color: var(--primary-variant);
    }
    tr>td{        
        padding-left: .5rem;
        width: 50%;
    }
    tr>td:nth-child(even){
        text-align: center;
    }
    p{
        margin: 0;
        max-width: 90%;
    }
    button{
        line-height: 1rem;
    }
    button > [class^="bi-"]::before{
        padding-left: 1rem;        
    }
    @media screen and (min-width: 768px) {
        .title > p, .title > i{
            font-size: 2rem;
        }
        .card-content{
            padding: 4rem var(--padding1);
        }
        .content, .link{
            font-size: 1.2rem;
        }    

    }
    @media screen and (min-width: 1024px) {
        .card, .card:nth-child(odd){
            align-self: stretch;
            background-color: var(--secondary-variant);
            max-width: 30%;
        }
        .card, .card:first-child, .card:last-child{
            margin: 1rem .5rem !important;
        }
        
        .card-content{
            padding: 1.5rem;
        }
        .title > p, .title > i, .phone{
            font-size: 1.5rem;
        }
        .content > p{
            max-width: 75%;
        }
        .content, .link, .footer > p{
            font-size: 1rem;
        }
        .footer{
            margin-top: 0;
        }
        .link{
            padding-top: 1rem;
        }        

    }
    @media screen and (min-width: 1750px){
        .card{
            width: 20%;
        }
    }
    button{
      width: auto;
      background-color: var(--font-dark);
      color: #fff;
      margin-bottom: 0px;
    }
    button:hover {
      background-color: var(--highlight);
      color: var(--font-dark)
    }
</style>