<template>
  <div class="home">        
    <intro :image="true" title="Welcome!" :content="introParagraphs" button-text="Book an Appointment" button-link="contact" :links="introLinks" id="intro"/>
    <cards/>   
    <more-info :img="true" :image="moreInfoImage" :heading="moreInfoHeading" :content="moreInfoParagraphs" :button="moreInfoButton" />
  </div>
</template>

<script>

import Intro from '../components/Intro.vue'
import Cards from '../sections/Cards.vue'
import MoreInfo from '../components/MoreInfo.vue'
import banner from '../assets/BROADSTREET-118.jpg'

export default {
  name: 'Home',
  components: {
    Intro,
    Cards,
    MoreInfo,
},
     data(){
      return {
        moreInfoHeading: "We aim to provide the best dental care possible.",
        moreInfoParagraphs: [
          "At Broad Street Dental Practice we have a great team who provide the very best care for our patients. Our facilities are equipped to accommodate patients with mobility issues.",
          "We hope that you are entirely satisfied with your dental care. If not, please let us know, so that we can improve our service."
        ],
        moreInfoButton: "Read More",
        moreInfoImage: banner,
        introParagraphs: [
          "To the Broad Street Dental Practice in Whittlesey. We endeavour to provide the best possible care and treatment suited to individual needs in a relaxed and friendly environment.",
          "Our patients receive the very best in dental care, from good family dentistry to advanced restorative treatments."
        ],
        introLinks: [
          {"link": "General Dentistry", "dest": "/dentalcare/general"},
          {"link": "Aesthetic Dentistry", "dest": "/dentalcare/restorative"},
          {"link": "Orthodontics", "dest": "/dentalcare/orthodontics"},
          {"link": "Dental Implants", "dest": "/dentalcare/dentalimplants"}
        ],
      }
     }
}
</script>

<style>    
</style>
