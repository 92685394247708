<template>
  <div id="app">
    <main-nav/>
    <router-view/>
    <div class="partners">
      <partner Image="denplan.png"/>
      <partner Image="bda2.png"/>
    </div>
    <img class="logo" :src="Logo"/>
    <Footer/>
    <div v-if="scrollBtn" @click="toTop" id="scrollback"><i class="bi-arrow-up"></i></div>
  </div>
</template>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Alex+Brush:wght@400;700&display=swap');
    :root{
      /* --dgreen: #265B2D;
      --lgreen: #D1E9B9 ;
      --bggreen: #F6FAF5;
      --accentlight: #B76A57;
      --accentdark: #683427;
      --bordercolor: #D8D8D8; */

      --primary: #1a3444;
      --primary-variant: #e4eeef;
      --secondary: #141a21;
      --secondary-variant: #cadddf;

      --font-light: #cadddf;
      --font-dark: #1a3444;

      --background: #E4EEEF;
      --foreground: #111;
      --highlight: #fedbb4;

      --padding1: 4rem;
      --padding2: 10rem;

    }
    body, textarea, input{
      padding: 0;
      margin: auto;
      font-family: 'Poppins', sans-serif;
      font-size: 1rem;
      max-width: 1980px;      
    }
    #app{
       background:  linear-gradient(to bottom, #141a21 0%,#1a3444e0 100%), url('./assets/cards_bg.png');
       background-attachment: fixed;
    }
    button, a{
        cursor: pointer;
    }
    button{
        display: flex;
        width: 90%;
        padding: 1rem;
        background-color: var(--background);
        color: var(--primary);
        font-size: 1rem;
        font-weight: 700;
        border: 0;
        margin: 2rem 0;
        justify-content: center;
    }
	button.dark{
		background-color: var(--primary);
		color: var(--background);
	}
	button:hover{
		background-color: var(--highlight);
	}
    h1{
      font-weight: 700;
        size: 3rem;
        font-family: "Alex Brush";
    }
    h2{
        font-family: 'Poppins', sans-serif;
        size: 3rem;
        font-weight: 700;
        text-align: left;
    }
    button > svg{
        padding-left: .5rem;
        align-self: center;
    }
    .title{
      font-family: 'Alex Brush';
      font-size: 3rem;
    }
    .logo{
      padding: 1rem 1.5rem;
    }    
    #scrollback{
        position: fixed;
        right: 1rem;
        bottom: 2rem;
        border-radius: 50%;
        mix-blend-mode: exclusion;
        text-align: center;
        padding: 0.7rem 1rem;
        background-color: var(--highlight);
        color: var(--foreground);
      cursor: pointer;
    }
    #scrollback:hover{
        mix-blend-mode: hard-light;
    }
    #scrollback > [class^="bi-"]::before{
        font-weight: bold !important;
    }
    .partners{
		width: 100%;
		background-color: var(--primary-variant);
    }
    @media screen and (min-width:768px) {
        .partners{
			display: flex;
			justify-content: center;
			width: 100%;
		}
        .logo{
        padding: 2rem 8rem;
        }
    }
    @media screen and (min-width:1024px){
        
        .logo{
            display: flex;
            margin: auto;
            width: 25rem;
        }
    }
    
</style>

<script>
import MainNav from './components/MainNav.vue'
import Footer from './components/Footer.vue'
import Partner from './components/Partner.vue'
import Logo from './assets/dark/BSDP_DP_WHITTLESEY_SEAL_LOGO.svg'

export default {
  data(){
    return{
      Logo,
      scrollBtn: false
    }
  },
  name: 'app',
  components: {
    MainNav,
    Partner,
    Footer
  },
  methods:{
    toTop(){
        window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
    },
    handleScroll(){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            this.scrollBtn = true;
        } else {
            this.scrollBtn = false;
        }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },

}
</script>
