<template>
    <div class="container">
        <div class="footer">
            <div>
            <h3>MENU</h3>
                <ul>
                    <router-link to="/home"><li>Home</li></router-link>
                    <router-link to="/dentalcare"><li>Dental Care</li></router-link>
                    <router-link to="/prices"><li>Fees</li></router-link>
                    <router-link to="/staff"><li>Our Staff</li></router-link>
                    <router-link to="/info"><li>Info & News</li></router-link>
                    <router-link to="/contact"><li>Contact</li></router-link>
                  <br/>
                  <router-link to="/privacy"><li>Privacy & Cookies</li></router-link>
                </ul>
            </div>
            <div>
                <h3>CONTACT</h3>
                <ul>
                    <li>Phone: <a href="tel:01733205656">01733 205656</a></li>
                    <li>Email: <a href="mailto:info@broadstreet.dental">info@broadstreet.dental</a></li>
                </ul>
                <h3>ADDRESS</h3>
                <ul>
                    <li>Broad Street Dental Practice, <br/>13 Broad Street, Whittlesey<br/> Peterborough PE7 1HA</li>
                </ul>
            </div>
            <div>
            <h3>OPENING HOURS</h3>
            <table>
                <colgroup>
                    <col span="1" style="width: 20%;">
                    <col span="1" style="width: 80%;">
                </colgroup>

                <tr><td>Mon.</td><td>8.30 - 5.00</td></tr>
                <tr><td>Tues.</td><td>8.30 - 5.00</td></tr>
                <tr><td>Wed.</td><td>8.30 - 7.30</td></tr>
                <tr><td>Thurs.</td><td>8.30 - 5.00</td></tr>
                <tr><td>Fri.</td><td>8.30 - 4.00</td></tr>
                <tr><td>Sat.</td><td>8.30 - 12.00 (1 per month)</td></tr>
            </table>
            <table>
                <colgroup>
                    <col span="1" style="width: 50%;">
                    <col span="1" style="width: 40%;">
                </colgroup>
                <tr><br></tr>
                <tr><td colspan="2">Closed on Sundays</td></tr>     
            </table>
            </div>

        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .footer{
        display: flex;
        flex-direction: column;
        background-color: var(--secondary);
        padding: 1.5rem;
    }
    .container{
        padding: 0;
        margin-bottom: 0;
        color: var(--background)
    }
    ul, h3, li{
        padding: 0;        
    }
    a{
        color: var(--background);
        text-decoration: underline;
    }
    li{
        list-style: none;

    }
    @media screen and (min-width: 768px){
        .footer{
            flex-direction: row;
            justify-content: space-evenly;            
        }
        .footer > div {
            padding: 1rem;
        }
        tr > td {
            padding-right: 1rem;
            vertical-align: text-top;
        }
    }
</style>