<template>
    <div  v-if="Image" class="partner" :style="{ 'background-image': 'url('+ require('../assets/'+Image+'') +')'}"/>
</template>

<script>
export default {
    props:{
        Image: String,
    }
}
</script>

<style scoped>
  .partner{
    display: block;
    padding: 5rem 0;
    margin: auto;
    background-size: contain;    
    max-width: 12rem;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media screen and (min-width:768px) {
    .partner{
        padding: 6rem 0;
        width: 50%;
    }
    }
  @media screen and (min-width:1024px) {
    .partner{
        width: 100%;
        padding: 3rem;
        margin: 2rem;
       
    }
  }
</style>