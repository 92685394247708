<template>
    <div :class="{toggled : menuToggled}" class="navigation">        
        <img :src="logo" class="logo">
        <a class="menu-toggle" v-on:click="toggleMenu()">{{caption}}<i :class="icon"/></a>   
        <div :class="{transition: menuToggled}" ref="links" class="links">
            <router-link :class="{transition: menuToggled}" @click.native="toggleMenu"  to="/home">Home</router-link>
            <router-link :class="{transition: menuToggled}" @click.native="toggleMenu" to="/dentalcare">Dental Care</router-link>
            <router-link :class="{transition: menuToggled}" @click.native="toggleMenu" to="/prices">Fees</router-link>
            <router-link to="/home" tag="img" :src="logo" class="logo"></router-link>
            <router-link :class="{transition: menuToggled}" @click.native="toggleMenu" to="/staff">Our staff</router-link>
            <router-link :class="{transition: menuToggled}" @click.native="toggleMenu" to="/info">Info & News</router-link>
            <router-link :class="{transition: menuToggled}" @click.native="toggleMenu" to="/contact">Contact</router-link>
        </div>
    </div>    
   
</template>

<script>
import logo from '../assets/dark/BSDP_DP_WHITTLESEY_TEXT_LOGO.svg'
export default {
    data(){
        return{
             menuToggled: window.innerWidth >= 1024,
             icon: 'bi-list',
             caption: '',
             background: '#141a21',
             logo
        }       
    },
    methods:{
        toggleMenu(){
            if (window.innerWidth < 1024){
                this.menuToggled =! this.menuToggled;
                if (!this.menuToggled){
                    this.icon = 'bi-list';
                }
                else{                
                    this.icon = 'bi-x-lg';
                }
            }
        }
    }
}
</script>

<style scoped>    
   
    .toggled{
        background: var(--secondary);
    }
    .navigation{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: var(--background);
        width: 100%;        
    }
    .navigation:after{
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        opacity: .5;
        background-color: var(--background);
    }
    .links> .logo{
        display: none;
    }
    .logo{
        width: auto;
        height: 2rem;
        align-self: center;
        margin: 0 auto;
    }   
    .menu-toggle > i{      
        color: var(--background);
        font-size: 1.5rem; 
        padding: 1.5rem;
        align-items: center;
    }    
    .menu-toggle{
        display: flex;
        font-weight: 500;
        text-align: center;
        align-items: center;       
    }
    .links{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 0;
        padding: 0 0.5rem;
        transition: height .3s ease-in;
        pointer-events:none;
    }
    .links.transition{
        height: 24rem;
        transition: height .3s ease;
        pointer-events:all;
    }
    .links a{
        font-size: 1.3rem;
        color: var(--white);        
        padding: 1rem;
        text-decoration: none;
        transition: opacity .3s ease;
        opacity: 0;           
    }
    .links a.transition{
        opacity: 1;
    }
    .emergency{
        color: black !important;
        background-color: var(--white);
        padding: .5rem;
        border-radius: 0rem;
    }
    .emergency > svg{
        margin-right: 1rem;
    }
   
    @media screen and (min-width: 1024px){
        .toggled{
            background: none;
        }
        .logo{
            display: none;
        }
        .links.transition{
            height: auto;
            flex-direction: row;      
            width: 100%;      
            justify-content: space-around;
        }
        .links.transition > a{            
            align-self: center;
            font-size: 1rem;
            padding: 1rem 0.3rem 0.3rem 0.3rem;
        }
        a:after{
            content: '';
            display: block;
            background-color: white;
            width: 0;
            margin-left: -100%;
            height: 2px;
            transition: margin-left .3s ease, width .3s ease;
        }
        a:hover:after, .router-link-active:after{
            width: 100%;
            margin-left: 0;
        }        

        .links.transition > .logo{
            display: block;
            padding: 1rem 0;
            margin: 0;
            height: 3rem;
        }
        .menu-toggle{
            display: none;
        }
          
    }
    @media screen and (min-width: 1440px){
        .navigation{
            height: 6rem;
        }
        .logo{
            height: 3rem;
            padding: 0.5rem;
        }
    }
    @media screen and (min-width: 2560px) {
        .links > a{
            font-size: 1vw;
        }
    }
</style>