<template>
  <div class="container">
    <div class="cards">
        <card icon="bi-exclamation-circle" title="Emergency care" :content="emergencyCareContent" phone="01733 205656"/>
        <card icon="bi-clock" title="Opening hours" :hours="openingHours" :footer="hoursFooter" />
        <card icon="bi-telephone" title="Contact info" :content="contactInfoContent" link="Open with Google Maps" linkdest="https://goo.gl/maps/o2mzTZGcE1QxZsqX8" />
    </div>
  </div>
</template>

<script>
import Card from '../components/Card.vue'

export default {
  components: { Card },
  data(){
       return{          
             openingHours: {
               'Monday': '8.30 - 5.00',
               'Tuesday': '8.30 - 5.00',
               'Wednesday': '8.30 - 7.30',
               'Thursday': '8.30 - 5.00',
               'Friday': '8.30 - 4.00',
               'Saturday': '8.30 - 12.00*'
             },
             hoursFooter: ['\nClosed on Sundays', 'Closed for lunch 12.30-1.30','*(1 per month)'],
             emergencyCareContent: ['During working hours every effort will be made to see you the same day. Outside normal surgery hours, follow the instructions on the answer phone message for emergency provision.\n\n', 'Call us for emergency care'],
             contactInfoContent: ['Phone: <a style="text-decoration: underlined; color: var(--foreground);" href="tel:01733205656">01733 205656</a>', 'E-mail: <a href="mailto:info@broadstreet.dental" style="text-decoration: underlined; color: var(--foreground);">info@broadstreet.dental</a>\n\n', '<b>ADDRESS</b>\nBroad Street Dental Practice\n 13 Broad Street Whittlesey Peterborough PE7 1HA']
             
    }
   }  
}
</script>

<style scoped>
  .container{
    padding: 0;
  }
 .cards{
      display: flex;
      flex-direction: column;
      justify-content: stretch;      
    }

  @media screen and (min-width: 1024px) {
    .cards{
      background-color: var(--primary-variant);
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
      padding: 1rem var(--padding1);
      margin-top: 0;
    }
  }
</style>